import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "電子カルテシステム開発の難しさを解決するためのフルマネージドサービスの活用",
  "date": "2018-05-21T09:06:52.000Z",
  "slug": "entry/2018/05/21/180652",
  "tags": ["medley"],
  "hero": "./2018_05_21.png",
  "heroAlt": "AWS"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`4 月末に新たにリリースしたクラウド型電子カルテ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/karte"
      }}>{`CLINICS カルテ`}</a>{`」の開発を担当している田中です。`}</p>
    <p>{`電子カルテという医療行為を支えるプロダクト開発ならではの醍醐味や難しさを感じる日々を過ごしています。前回は`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2018/05/18/130000"
      }}>{`CLINICS カルテのデザインについてマエダが紹介しました`}</a>{`が、今回はエンジニアから見た苦悩と葛藤についてお話します。`}</p>
    <p>{`苦労した点としては、医療事務の業務そのものの複雑さやそれに伴うアプリケーション開発の複雑さはもちろんのこと、 `}<strong parentName="p">{`電子カルテ開発の特徴として関連省庁のガイドラインの準拠やレセプトソフト（医療会計専用のシステム）のシステム管理など、多岐にわたり対応が必要なこと`}</strong>{` が挙げられます。`}</p>
    <p>{`ガイドラインへの対応に関するお話は細かくなってしまうので、大まかな内容として、今回は主にレセプトシステムとして連携している ORCA のシステム管理方法についてお伝えさせていただければと思います（ORCA に関する説明は後述します）。`}</p>
    <h1>{`ガイドラインへの対応`}</h1>
    <p>{`電子カルテのように医療情報を扱う際に遵守する必要があるガイドラインとして 3 省 4 ガイドライン（厚生労働省、経済産業省、総務省の 3 省が出している 4 つのガイドライン）があります。`}</p>
    <p>{`CLINICS カルテの開発を進めるにあたり、このガイドラインがシステム設計や運用に大きな影響を及ぼすため、まずはガイドラインを読み込み整理するところから始めました。`}</p>
    <p>{`システム、アプリケーションともに対応すべきことは多かったのですが、システム構成に特に影響が大きかったのは以下の 2 点となります。`}</p>
    <ul>
      <li parentName="ul">{`サービス提供に用いるシステム、アプリケーションを日本国内法の適用が及ぶ場所に設置すること`}</li>
      <li parentName="ul">{`クライアント証明書を利用した TLS クライアント認証を実施すること`}</li>
    </ul>
    <p>{`CLINICS カルテは弊社で既に運用しているオンライン診療アプリ「CLINICS」と連携させる前提であったため、ガイドラインに即したシステム構成としていくために、まず CLINICS のシステムを Heroku から AWS に移行することから始まりました。`}</p>
    <p>{`また、クライアント認証を行うために AWS の構成を色々と検討し、Nginx とも日々格闘したりしました。。。`}</p>
    <p>{`これらの対応内容に関連するブログも過去に書いていますので、興味がある方はぜひご参考にしてください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_01"
      }}>{`https://developer.medley.jp/entry/2017/08/24/120000_01`}</a>{`
`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_02"
      }}>{`https://developer.medley.jp/entry/2017/08/24/120000_02`}</a>{`
`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/09/22/124000"
      }}>{`https://developer.medley.jp/entry/2017/09/22/124000`}</a></p>
    <h1>{`ORCA サーバの管理方法`}</h1>
    <p>{`CLINICS カルテは、日本医師会 ORCA 管理機構株式会社が提供する医療会計ソフトである「ORCA」を組み込んだ「ORCA 内包型」のクラウド型電子カルテです。`}</p>
    <h2>{`参考: ORCA とは`}</h2>
    <p>{`医療現場 IT 化を推進する日本医師会が会員等のために提供しているレセプトソフト（診療報酬の請求業務を行うための医療会計ソフト）。2002 年にオープンソースとして公開され、現在、診療所を中心に 17,000 を超える全国の医療機関に導入されています。`}</p>
    <p>{`医療情報ネットワーク推進委員会にて「医師会総合情報ネットワーク構想」（1997 年 情報化検討委員会）を構成するツールの一つとして認められた日本医師会の研究事業プロジェクト「ORCA プロジェクト」が開発、提供を開始したものです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.orca.med.or.jp/orca/summary/outline.html"
      }}>{`https://www.orca.med.or.jp/orca/summary/outline.html`}</a></p>
    <p>{`CLINICS カルテはおおまかに以下のような構成となっており、Ruby on Rails で稼働しているカルテアプリケーションと医療機関ごとの ORCA サーバがあり、その間を ORCA が提供している API で接続しています。`}</p>
    <p>{`（下図は一例として上げているもので台数や詳細な構成は実際のシステムとは異なります）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180521/20180521152610.png",
      "alt": "20180521152610.png"
    }}></img>
    <p>{`この ORCA サーバですが、医療機関ごとに 1 つの EC2 インスタンス構成となっており、利用する医療機関が増える度に EC2 インスタンスが増えるため、導入医療機関が増えるほど構築も運用もつらくなっていきます。このあたりを出来るだけ自動化したお話が今回のメインテーマです。`}</p>
    <p>{`（これよりももっと楽ができる構成にしようと色々検討もしたのですが、システムの特殊性などの諸事情があり、結果 EC2 インスタンス単位で管理する構成になっています）`}</p>
    <h1>{`ORCA サーバー関連で主に使用した AWS サービス`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180521/20180521152646.png",
      "alt": "20180521152646.png"
    }}></img>
    <p>{`AMI 作成やインスタンス作成などのビルド系は`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/codebuild/"
      }}>{`CodeBuild`}</a>{`、パッチ適用やコマンド実行などのインスタンス管理は`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/systems-manager/"
      }}>{`Systems Manager`}</a>{`、脆弱性チェックなどのセキュリティ関連には`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/guardduty/"
      }}>{`Guard Duty`}</a>{`といったサービスを主に利用しています。`}</p>
    <p>{`次に、インスタンス構築に関してもう少し具体的に説明します。`}</p>
    <h1>{`ORCA サーバのインスタンス構築`}</h1>
    <h2>{`ORCA サーバ用 AMI（ゴールデンイメージ）作成`}</h2>
    <p>{`ビルドの実行は CodeBuild で行っています。AMI のビルドには`}<a parentName="p" {...{
        "href": "https://www.packer.io/"
      }}>{`Packer`}</a>{`を使用しており、ビルド完了時に作成された AMI の ID をパラメータストアに登録しています。この AMI ID をインスタンス作成時に参照します。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180521/20180521152801.png",
      "alt": "20180521152801.png"
    }}></img>
    <p>{`参考までに、CodeBuild で使用している buildspec.yaml は以下のようになります（説明箇所など、一部実際とは異なります）。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`phases`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`pre_build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
     `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## 説明`}</span>{`
     * packer と jq をビルド用コンテナにインストール

     `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`commands`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`qL `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`o packer.zip https`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//releases.hashicorp.com/packer/1.1.3/packer_1.1.3_linux_amd64.zip `}<span parentName="code" {...{
            "className": "token important"
          }}>{`&&`}</span>{` unzip packer.zip
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`qL `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`o jq https`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//stedolan.github.io/jq/download/linux64/jq `}<span parentName="code" {...{
            "className": "token important"
          }}>{`&&`}</span>{` chmod +x ./jq

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
     `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## 説明`}</span>{`
     * 処理に必要な AWS credential 情報を取得＆設定（後の aws cli 使う用）
     * packer ビルド実行、AMI 作成
     * packer ビルド結果から作成された AMI ID を取得
     * AMI ID をパラメータストアに登録

     `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`commands`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`qL `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`o aws_credentials.json https`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//169.254.170.2/$AWS_CONTAINER_CREDENTIALS_RELATIVE_URI `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{` aws_credentials.json
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` aws configure set region $AWS_REGION
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` aws configure set aws_access_key_id \`./jq `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`r '.AccessKeyId' aws_credentials.json\`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` aws configure set aws_secret_access_key \`./jq `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`r '.SecretAccessKey' aws_credentials.json\`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` aws configure set aws_session_token \`./jq `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`r '.Token' aws_credentials.json\`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ../packer build `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`machine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`readable bin/packer.json `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` tee packer.log
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` cat packer.log `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` grep "artifact`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`id" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` cut `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`d "`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`f6 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` cut `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`d "`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`f2 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{` ami.txt
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ORCA_AMI_ID=\`cat ami.txt\`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` aws ssm put`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`parameter `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`name ORCA_AMI_ID `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`value $ORCA_AMI_ID `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`type String `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`overwrite`}</code></pre></div>
    <h2>{`ORCA サーバ用 EC2 インスタンス作成`}</h2>
    <p>{`医療機関用のアカウントを新規追加する場合、社内用の管理ツールでまず医療機関の基本情報を登録します。その後、ORCA インスタンス設定に必要なパラメータ（医療機関を識別する ID など）を設定し、インスタンス作成のビルド処理を実行します。`}</p>
    <p>{`CodeBuild がゴールデンイメージ用の AMI をベースに ORCA インスタンスを構築します。ORCA インスタンスは起動時に各種設定処理を行います（cloud-init）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180521/20180521152838.png",
      "alt": "20180521152838.png"
    }}></img>
    <p>{`なお、インスタンス起動時に行っている処理として主に以下のようなことを行っています。`}</p>
    <ul>
      <li parentName="ul">{`該当インスタンスの内部 IP を Private DNS に登録`}</li>
      <li parentName="ul">{`ORCA のセットアップ/プログラム/マスタ更新`}</li>
      <li parentName="ul">{`Postgres のバックアップ設定`}</li>
      <li parentName="ul">{`Mackerel や Systems Manager 等の各種 Agent インストール、設定`}</li>
    </ul>
    <h2>{`ORCA サーバ運用`}</h2>
    <p>{`構築が完了し稼働した後も、ORCA 自体のアップデートやパッチの適用など様々な運用が発生します。それらをどのように行っているか、まだ改善中ではありますが一部ご紹介します。`}</p>
    <h3>{`インスタンスに対する各種操作の実行`}</h3>
    <p>{`ORCA のアップデートなど、定期的に行う操作に必要なコマンドを document として登録し、RunCommand で特定インスタンスまたは複数インスタンスに一括実行できるようにしています。`}</p>
    <h3>{`ORCA アプリケーションの死活監視`}</h3>
    <p>{`ORCA アプリケーション自体構成が複雑で、いくつものミドルウェアと連携して動いています。これらの各種プロセス/ログ監視は行った上で、API として正しく稼働しているかを確認するための死活監視を Mackerel のカスタムメトリクスとして登録し、一定数以上失敗した場合は各種プロセスを再起動し、それでも正常に動かない場合は`}<a parentName="p" {...{
        "href": "https://mackerel.io/ja/"
      }}>{`Mackerel`}</a>{`から Slack に通知させています。`}</p>
    <h3>{`セキュリティチェック`}</h3>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/cloudtrail/"
      }}>{`CloudTrail`}</a>{`や VPC フローログなどをもとに、GuardDuty で定期的にチェックし脆弱性が見つかった場合は Slack に通知させるようにしています。その他、セキュリティパッチ適用などもパッチマネージャーを利用し自動化させています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`電子カルテのシステムは検査や画像データなどを取り扱う外部システムと連携したいという需要もあります。そのような連携も視野に入れるとデータ標準化の問題などもあり難しい分野ではありますが、CLINICS カルテとして日々進化していくためにも、フルマネージドサービスなど活用できるところは積極的に活用し、集中すべき問題を解決していきたいと思います。`}</p>
    <p>{`また、電子カルテのシステムとしての性質上、絶対に落とさないシステム運用が必要です。さらに堅牢なシステムにするために、トラブルを未然に防ぐような非機能面の強化や日々のモニタリングなど地道なことも含め、もっともっと取り組んでいく必要があります。`}</p>
    <p>{`まだまだプロダクトとして未成熟な段階ではありますが、「医療の課題を解決する」ため、CLINICS カルテでは医療業務を効率化し、医療プラットフォームとなるべく進化していきたいという構想を持っています。（CLINICS カルテが目指す医療のプラットフォームとは？という話は、こちらで詳しく書かれています）`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/118281"
      }}>{`https://www.wantedly.com/companies/medley/post_articles/118281`}</a></p>
    <p>{`CLINICS カルテは、まずは多くの医療機関に使ってもらうものではありますが、将来的には、患者さんと医療機関をつなぎ、診療や検査データなどをやりとりするプラットフォームとなる予定です。こうした新しい医療のプロダクトに興味のあるデザイナー・エンジニア・ディレクターの方はぜひこちらまでご応募おまちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      